
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import OuterSelect from '@/components/organisms/dms/OuterSelect/OuterSelect.vue'
import Breadcrumb from '@/components/organisms/dms/common/Breadcrumb.vue'
import StopSend from '@/components/organisms/dms/common/StopSend.vue'

export default defineComponent({
  components: {
    Breadcrumb,
    OuterSelect,
    StopSend,
  },
  setup () {
    const router = useRouter()
    const list = [
      { text: '再利用する資料を選択', numFlag: true },
      { text: 'メタデータを入力', numFlag: true },
      { text: 'ファイルをアップロード', numFlag: true },
      { text: '内容確認', numFlag: true },
      { text: '完了', numFlag: false },
    ]
    return {
      router,
      list,

      async toTop () {
        await router.push('/dms/outer')
      },
    }
  },
})
