
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { getDataCredential } from '@/helpers/util/webApiUtil'
import { convertPermission } from '@/helpers/util/dmsUtils'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterSelect',
  components: {
    DssIcon,
    AppButton,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const result = ref([])

    const getMetadata = async () => {
      const url = `${BASE_URL}/outer/item/list`
      const response = await getDataCredential(url)
      if (response.status === 200) {
        result.value = response.data
      }
    }
    getMetadata()

    const formatJst = (dateJst: string) => {
      if (dateJst && dateJst.includes('T') && dateJst.includes('+09:00')) {
        return dateJst.replace('T', ' ').replace('+09:00', '').split('.')[0]
      }
      return '-'
    }

    const reuse = (pid: string) => {
      store.dispatch('setS3UploadFileList', [])
      store.dispatch('setContentsData', {})
      store.dispatch('setThumbnail', [])
      store.dispatch('setReacquireFlag', true)
      store.dispatch('setReusePid', pid)
      router.push('metadataInput')
    }

    return {
      result,
      convertPermission,
      formatJst,
      reuse,
    }
  },
})
